import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import "../assets/style.css";
import Modal from "react-bootstrap/Modal";
import { BsDot } from "react-icons/bs";
import { GoChevronLeft } from 'react-icons/go';
import { HiArrowNarrowRight } from 'react-icons/hi';

import line from "../assets/images/bg_coin_line.jpg";

import NavbarOne from "./siteTheme/NavbarOne";
import Footer from "./siteTheme/Footer";
import { makeRequest } from "../core/services/v1/request";
import Config from "../core/config";
import { toast } from "../core/lib/toastAlert";
import { useContextData } from '../core/context/index';
import { getTokenCreatePackageList_API, getTokenCreateRequest_API } from "../core/services/tokenCreate.api";

export default function Token(props) {
  const navigate = useNavigate();
  const { siteSettings, myProfile, setUserProfile } = useContextData();

  const [isloadingData, setIsloadingData] = useState({});
  const [packagePopup, setPackagePopup] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [tokenDetailList, setTokenDetailList] = useState([]);
  const [requestErrors, setRequestErrors] = useState([]);
  const [requestDetails, setRequestDetails] = useState({
    network: "BEP20",
    name: "",
    symbol: "",
    totalSupply: "",
    decimal: "",
  });
  const handleChange = (event, key) => {
    let value = event.target.value;
    if(key == "totalSupply" || key == "decimal") {
      if(value && isNaN(parseInt(value)) == false ) {
        value = parseInt(value);
      }
      else {
        return false;
      }
    }
    let details = requestDetails;
    details[key] = value;
    setRequestDetails({ ...details });
  };

  const loadingChange = (data = {}) => {
    const dataCopy = Object.assign({}, isloadingData);
    dataCopy[data.key] = data.value;
    setIsloadingData(dataCopy);
  }

  const getTokenCreatePackageList_call = async () => {
    // if (myProfile && myProfile.kycstatus != 1) {
    //   toast({ type: "error", message: "Please verify your KYC." });
    //   return false;
    // }
    loadingChange({ key: "getTokenCreatePackage_API", value: true });
    const resp = await getTokenCreatePackageList_API();
    loadingChange({ key: "getTokenCreatePackage_API", value: false });
    if(resp.status === true && resp.data && resp.data.list && resp.data.list.packages) {
      setTokenDetailList(resp.data.list);
    }
    else {
      setTokenDetailList([]);
    }
  }
  const submitRequest = async () => {
    // if (myProfile && myProfile.kycstatus != 1) {
    //   toast({ type: "error", message: "Please verify your KYC." });
    //   return false;
    // }
    loadingChange({ key: "getTokenCreatePackage_API", value: true });
    const payload = requestDetails;
    payload.packageName = selectedPackage.packageName;
    const resp = await getTokenCreateRequest_API({payload: requestDetails});
    loadingChange({ key: "getTokenCreatePackage_API", value: false });
    if(resp.status === true && resp.message) {
      toast({ type: "success", message: resp.message });
      setRequestErrors([]);
      setRequestDetails({
        network: "BEP20",
        name: "",
        symbol: "",
        totalSupply: "",
        decimal: "",
      });
    }
    else if(resp.status === false && resp.message) {
      toast({ type: "error", message: resp.message });
      if(resp.errors) {
        setRequestErrors(resp.errors);
      }
      else {
        setRequestErrors([]);
      }
    }
  }

  useEffect(() => {
    getTokenCreatePackageList_call();
  }, []);
  return (
    <div>
      <NavbarOne
        setTheme={props.setTheme}
        theme={props.theme}
        pageName={"Token"}
      />
      <div className="under-line-effect">
        <img src={line} alt="bg_coin_line" />
      </div>
      <div className="earn-banner-container">
        <div className="row earn-banner-inside mx-auto">
          <div className="col-lg-12">
            <div className="earn-header">{Config.SITENAME}</div>
            {(tokenDetailList && tokenDetailList.detail) && <div className="earn-content">{tokenDetailList.detail}</div>}
          </div>
        </div>
      </div>

      <div className="deposit-hero-section">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 text-end">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeNeTdPDN3YDFHcDWPV6eGsu80pJ57EcWB6DxWRRMKexgLUlg/viewform" target="_blank" type="button" className="deposit-fiat-button btn-next-1 launchExchange">
                Launch Your Own Crypto Exchange
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="price-card-main-container flex lg:flex-row  lg:justify-content-center align-items-center lg:p-8 p-4 font-sans text-white bg-bluegray-900 min-h-screen">
        {
        (tokenDetailList && tokenDetailList.packages && tokenDetailList.packages.length !== 0) &&
          tokenDetailList.packages.map((val, key) => {
            return (
              <div className="price-card-container lg:w-23rem w-full border-2 lg:border-right-none border-bluegray-800 p-5">
                <div className="pb-3 mb-4 border-bottom-1 border-bluegray-800">
                  <div className="text-xs text-bluegray-400 mb-2">
                    {val.packageName}
                    <div className="text-align-right">{val.price} USDT</div>
                  </div>
                  <h2 className="text-5xl m-0 font-normal">{val.days} days</h2>
                </div>
                <div className="mb-4 token-price-container">
                  {val.accessDetails.map((value, index) => {
                    return (
                      <div className="flex align-items-center mb-2">
                        {value && <span>&#10003;</span>}
                        {value && (
                          <span className="token-nav-name">{value}</span>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="mt-auto mt-3 w-full">
                  <button
                    onClick={() => {
                      setPackagePopup(true);
                      setSelectedPackage(val);
                      setRequestDetails({
                        network: "BEP20",
                        name: "",
                        symbol: "",
                        totalSupply: "",
                        decimal: "",
                      });
                    }}
                    className="token-price-btn bg-bluegray-800 cursor-pointer text-white border-none w-full flex align-items-center px-3"
                  >
                    Create
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      <Modal
        show={packagePopup === true}
        onHide={() => {
          setPackagePopup(false);
        }}
      >
        <Modal.Body>
          {" "}
          <div className="row dropdown-container">
            <div className="row p-2 ">
              <div className="col-11 p-0">
                <span>{selectedPackage.packageName}</span>
              </div>
              <div className="col-1 p-0 coin-popup-close">
                <div
                  onClick={() => {
                    setPackagePopup(false);
                    setRequestDetails({
                      network: "BEP20",
                      name: "",
                      symbol: "",
                      totalSupply: "",
                      decimal: "",
                    });
                  }}
                >
                  X
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="mt-2 token-popup-header">
                {/* {JSON.stringify(requestErrors)} */}
                {/* {JSON.stringify(requestDetails)} */}
              <div className="row mb-3">
                <div className="col-3 token-popup-label">Network</div>
                <div className="col-9">
                  <select
                    className="form-control token-input"
                    type="text"
                    name="network"
                    label="network"
                    autoComplete="off"
                    value={requestDetails.network}
                    onChange={(e) => handleChange(e, "network")}
                  >
                    <option value={"BEP20"}>BEP20</option>
                    <option value={"ERC20"}>ERC20</option>
                    <option value={"TRC20"}>TRC20</option>
                    <option value={"MATIC20"}>MATIC20</option>
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 token-popup-label">Name</div>
                <div className="col-9">
                  <input
                    className="form-control token-input"
                    type="text"
                    name="name"
                    label="name"
                    autoComplete="off"
                    value={requestDetails.name}
                    onChange={(e) => handleChange(e, "name")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3  token-popup-label">Symbol</div>
                <div className="col-9">
                  <input
                    className="form-control token-input"
                    type="text"
                    name="symbol"
                    label="symbol"
                    autoComplete="off"
                    value={requestDetails.symbol}
                    onChange={(e) => handleChange(e, "symbol")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 token-popup-label">Total Supply</div>
                <div className="col-9">
                  <input
                    className="form-control token-input"
                    type="text"
                    name="totalSupply"
                    label="totalSupply"
                    autoComplete="off"
                    value={requestDetails.totalSupply}
                    onChange={(e) => handleChange(e, "totalSupply")}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3 token-popup-label">Decimal</div>
                <div className="col-9">
                  <input
                    className="form-control token-input"
                    type="text"
                    name="decimal"
                    label="decimal"
                    autoComplete="off"
                    value={requestDetails.decimal}
                    onChange={(e) => handleChange(e, "decimal")}
                  />
                </div>
              </div>
              <hr></hr>
              <div className="row justify-content-center mt-3">
                <div className="col-2">
                  <button
                    onClick={() => {
                      submitRequest();
                      // setPackagePopup(false);
                      // setRequestDetails({
                      //   name: "",
                      //   symbol: "",
                      //   totalSupply: "",
                      //   decimal: "",
                      // });
                    }}
                    className="token-price-btn bg-bluegray-800 cursor-pointer text-white border-none w-full flex align-items-center px-3"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
}
